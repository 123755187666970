import { lazy, Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import Cookies from '@comeon/tomcat-cookies';
import { Spinner } from '@comeon/ui';

import { requestApi } from '~/services/api';
import getRootElement from '~/utils/get-root-element';
import { loadScript } from '~/utils/globals';

import { register, unregister } from './service-worker-registration';

const API_BASE_PATH = process.env.API_BASE_PATH || '';

function setDefaultCookie() {
	const defaultCookie = Cookies.get('locale');
	if (defaultCookie) {
		return Promise.resolve(defaultCookie);
	}
	return requestApi(`${API_BASE_PATH}/rest/state/locale/cookie`, {}, 'POST');
}

async function preloadState() {
	try {
		const { result } = await requestApi(`${API_BASE_PATH}/rest/state/state`);
		return {
			account: result.account.result,
			authentication: result.authentication.result,
			brand: result.brand.result,
			translations: result.translations.result,
			support: result.support.result
		};
	} catch (error) {
		throw new Error(error);
	}
}

function loadFonts({ googleFonts, typekitId, className }) {
	const WebFontConfig = {
		...(googleFonts ? { google: { families: JSON.parse(googleFonts) } } : {}),
		...(typekitId ? { typekit: { id: typekitId, class: className } } : {})
	};
	// const WebFontConfig = googleFonts
	// 	? { google: { families: JSON.parse(googleFonts) } }
	// 	: { typekit: { id: typekitId, class: className } };
	if (Object.entries(WebFontConfig).length > 0) {
		return loadScript('https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js').then(() => {
			window.WebFont.load(WebFontConfig);
		});
	}
	return Promise.resolve(WebFontConfig);
}

async function setPreloadedState() {
	if (typeof window.__PRELOADED_STATE__ === 'undefined') {
		const preloadedState = await preloadState();
		window.__PRELOADED_STATE__ = preloadedState;
	}
	return window.__PRELOADED_STATE__;
}

async function setConfiguration() {
	if (typeof window.__CONFIGURATION__ === 'undefined') {
		const configuration = await requestApi(`${API_BASE_PATH}/rest/state/configuration`);
		window.__CONFIGURATION__ = configuration.result;
	}

	return window.__CONFIGURATION__;
}

async function handleServiceWorker() {
	const { enableServiceWorker } = await setConfiguration();
	if (enableServiceWorker) {
		return register();
	}
	return unregister();
}

const ServiceWorkers = () => {
	useEffect(() => {
		handleServiceWorker();
	}, []);
	return null;
};

async function mount() {
	const rootEl = getRootElement();
	const { googleFonts, typekitId, className } = rootEl.dataset;
	loadFonts({ googleFonts, typekitId, className });
	const Root = lazy(() => import('./root'));

	const root = createRoot(rootEl);
	root.render(
		<>
			<Suspense fallback={<Spinner />}>
				<Root />
			</Suspense>
			<ServiceWorkers />
		</>
	);
}

async function init() {
	await setDefaultCookie();
	await Promise.all([setPreloadedState(), setConfiguration()]);
	await mount();
}

init();
