// Added fakeLocalStorage to be able to load the site on private Safari
import '../store/fakeLocalStorage.js';

// BANJO-5522
window.history.scrollRestoration = 'manual';

function loadScript(src, attributes = {}) {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = src;
		script.onload = resolve;
		script.onerror = reject;
		const attrs = Object.keys(attributes);
		if (attrs.length > 0) {
			attrs.forEach(key => {
				script.setAttribute(key, attributes[key]);
			});
		}
		document.head.appendChild(script);
	});
}
function loadStyle(href) {
	return new Promise((resolve, reject) => {
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = href;
		link.onload = resolve;
		link.onerror = reject;
		document.head.appendChild(link);
	});
}
export { loadScript, loadStyle };
